<template>
  <div id="app">
    <div class="container-fluid">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name:"App",
  data() {
    return {
    }
  },
  components: {
  },
  mounted() {
  },
  methods: {
  },
  computed: {
  },
};
</script>
<style lang="scss" >
@import "@/scss/main.scss";
</style>