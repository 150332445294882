<template>
    <nav class="navbar row">
        <div class="container">

            <div class="navbar-logo col-lg-1">
                <router-link :to="LienLogo"><img src="@/assets/imgs/SPVI.png" fluid class="logospv" />
                </router-link>
            </div>
            <div class="col-lg-2 text-center pr-0 pl-0">
                Bonjour <span class="firstLetterUpper">{{ this.currentUser.prenom }}</span>
            </div>

            <div class="col-lg-9 text-right pr-0 pl-0">
                <ul id="navlinks">
                    <li class="nav-item" v-if="this.currentUser.role == 'admin'">
                        <router-link to="/gestionSaisies" exact-active-class="active" class="nav-link" v-slot="{isActive}">
                            <NavLink :active="isActive">
                                Gestion des saisies
                            </NavLink>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/home" exact-active-class="exact-active" class="nav-link" v-slot="{isActive}">
                            <NavLink :active="isActive">
                                Saisie
                            </NavLink>
                        </router-link>
                    </li>
                    <li class="nav-item" v-if="this.currentUser.role == 'admin'">
                        <router-link to="/facturation" class="nav-link">
                            Facturation
                        </router-link>
                    </li>
                    <li class="nav-item" v-if="this.currentUser.role == 'admin'">
                        <router-link to="/parametre" class="nav-link">
                            Paramètres
                        </router-link>
                    </li>
                    <li v-if="showUtilisateurs && this.currentUser.role == 'admin'" class="nav-item">
                        <router-link to="/liste-utilisateurs" class="nav-link">
                            Utilisateurs
                        </router-link>
                    </li>
                    <li v-if="currentUser" class="nav-item">
                        <router-link :active="nav-isActive" to="/profile" class="nav-link">
                            <font-awesome-icon icon="user" />
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" @click.prevent="logOut">
                            <font-awesome-icon icon="sign-out-alt" />
                        </a>
                    </li>
                </ul>
            </div>

        </div>
    </nav>

</template>

<script>
import { isSessionExpired } from "@/utilities";
export default {
    name: "Navigation",
    currentUser: {},
    data() {
        return {
            LienLogo: "/home",
            isActive: null
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        showUtilisateurs() {
            return this.currentUser && this.currentUser.role == 'admin';
        },
      
    },
    props: {
        pageName: String,
    },
    methods: {
        logOut() {
            localStorage.setItem("user",null);
            this.$store.dispatch('auth/logout');
            this.$router.push('/login');
        },
    },
    mounted: function () {
        var user = JSON.parse(localStorage.getItem("user"));
        if (!user) {
            this.$store.dispatch('auth/logout');
            this.$router.push('/login');
        } else {
            if (isSessionExpired()) {
                localStorage.setItem("user",null);
                this.$store.dispatch('auth/logout');
                this.$router.push('/login');
            }
        }

    }
};
</script>
<style lang="scss" scoped>
@import "@/scss/_variables.scss";
#navlinks li {
    display: inline-block;
    font-size: 0.8em;
    .nav-link {
        cursor: pointer;
        text-transform: uppercase;
    }
}


.header {
    padding: 12px 0 3px 0;
    background-color: #FFF;
    z-index:1000;
}

.navbar-menu-ul {
    display: flex;
}


</style>