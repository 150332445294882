import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Popper from "vue3-popper";

import Vue3EasyDataTable from 'vue3-easy-data-table';
//https://hc200ok.github.io/vue3-easy-data-table-doc/features/searching.html
//https://hc200ok.github.io/vue3-easy-data-table-doc/features/footer-customization.html
//https://hc200ok.github.io/vue3-easy-data-table-doc/features/style-customization.html
//Pour personnaliser les styles, voir https://github.com/HC200ok/vue3-easy-data-table/blob/main/src/scss/vue3-easy-data-table.scss
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
//import "./assets/css/main.css";
import 'vue3-easy-data-table/dist/style.css';
import { FontAwesomeIcon } from './plugins/font-awesome'
import Toaster from '@meforma/vue-toaster';
// Doc ici : https://meforma.github.io/vue-toaster/
// https://github.com/MeForma/vue-toaster
import axios from 'axios';
// https://vue-final-modal.org/
// https://codesandbox.io/examples/package/vue-final-modal
import { vfmPlugin } from 'vue-final-modal'
// https://www.npmjs.com/package/vue3-cookies
import { globalCookiesConfig } from "vue3-cookies";

// https://github.com/moreta/vue-search-select
import "vue-search-select/dist/VueSearchSelect.css"

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response != undefined && error.response.status === 401 || error.response.status === 403) {
    localStorage.clear();
    this.$store.dispatch('auth/logout');
    this.$router.push('/login');
    // this.$store.state.auth.status.loggedIn = false;
    // router.push('/');
  }
  else {
    return Promise.reject(error);
  }
})

globalCookiesConfig({
  expireTimes: "365d",
  path: "/",
  domain: "",
  secure: true,
  sameSite: "None",
});


const app = createApp(App)
  .use(router)
  .use(store)
  .use(vfmPlugin)
  .use(Toaster, {
    duration: 5000,
    position: "top",
  })
  .component("font-awesome-icon", FontAwesomeIcon);
app.component("Popper", Popper);
app.config.globalProperties.$http = axios;

app.component('EasyDataTable', Vue3EasyDataTable);
app.mount("#app");
  // Basé sur le tuto vue3 suivant :
  // https://www.bezkoder.com/vue-3-authentication-jwt/