<template>
    <div>
        <div class="footer row">
            <div class="col">
                <p class="text-center">
                    © {{ this.year }} SPV Informatique· Tous droits réservés
                </p>
            </div>
        </div>

    </div>
</template>
    
<script>

export default {
    name: "Footer",
    data() {
        return {
            year: "",
            CookiesAcceptes: false
        }
    },
    mounted: function () {
        this.getYear();
    },
    methods: {
        getYear() {
            var aujd = new Date();
            this.year = aujd.getFullYear();
        },
    }
}

</script>
    
<style lang="scss" scoped>
.footer {
    padding-top: 10px;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 900;
    position: fixed;
    background-color: #3D3D3D;
    color: #FFFFFF;

    a,a:active,a:hover {
        color: #FFFFFF;
    }
}

.button-fixed {
    z-index: 1000;
    bottom: 0;
    position: fixed;
    right: 0;
    border-radius: 4px;
}

.bg-custom {
    background-color: #130f40;
}

.fas {
    cursor: pointer;
    font-size: 24px;
    color: #FFFFFF;
}
</style>