import { createWebHistory, createRouter } from "vue-router";
import Home from "@/components/Home.vue";
import Login from "@/components/Login.vue";

// lazy-loaded
const MotDePasseOublie = () => import("@/components/Public/MotDePasseOublie.vue");
const ModifierMDP = () => import("@/components/Public/ModifierMDP.vue");
const Profile = () => import("@/components/profile/Profile.vue");
const ListeUtilisateurs = () => import("@/components/utilisateur/liste-utilisateurs.vue");
const Parametre = () => import("@/components/parametre/parametre.vue");
const GestionSaisies = () => import("@/components/gestionSaisies/gestionSaisies.vue");
const Saisies = () => import("@/components/saisies/saisies.vue");
const Facturation = () => import("@/components/facturation/facturation.vue");

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/home",
    component: Home,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/profile",
    name: "profile",
    // lazy-loaded
    component: Profile,
  },

  // Gestion des saisies
  {
    path:"/gestionSaisies",
    name: "gestionSaisies",
    // lazy-loaded
    component: GestionSaisies,
  },

  // Saisies
  {
    path:"/saisies/:idLot",
    name: "saisies",
    // lazy-loaded
    component: Saisies,
    params: true
  },
  
  // Paramètres
  {
    path: "/parametre",
    name: "parametre",
    // lazy-loaded
    component: Parametre,
  },

  //Facturation
  {
    path: "/facturation",
    name: "facturation",
    // lazy-loaded
    component: Facturation,
  },
  
  // Utilisateurs
  {
    path: "/liste-utilisateurs",
    name: "liste-utilisateurs",
    // lazy-loaded
    component: ListeUtilisateurs,
  },
  {
    path: "/mot-de-passe-oublie",
    name: "MotDePasseOublie",
    // lazy-loaded
    component: MotDePasseOublie,
  },
  {
    path: "/ModifierMDP",
    name: "ModifierMDP",
    // lazy-loaded
    component: ModifierMDP,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
    const publicPages = ['/login', '/mot-de-passe-oublie','/ModifierMDP/'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');

    window.document.title = to.meta && to.meta.title ? to.meta.title : 'SPVI';
    // trying to access a restricted page + not logged in
    // redirect to login page
    if (authRequired && !loggedIn) {
      next('/login');
    } else {
      next();
    }
  });
  
export default router;