import authHeader from '@/services/auth-header';
import axios from 'axios';


function getCurrentUser() {
    // return this.$store.state.auth.user;
    return JSON.parse(localStorage.getItem('user'));
}


function isSessionExpired() {
  let u = getCurrentUser();
  if (!u) {
    return true;
  } else {
    let expirationDate = new Date(u.dateExpirationSession);
    let now = new Date;
    return now > expirationDate;
  }
}

function getUrlWs () {
    var protocol = window.location.protocol;
    var hostname = window.location.hostname;
  
    if (hostname.toLowerCase() == "localhost") {
      return protocol + "//localhost:5217/api/";
    }
    else if (hostname.toLowerCase() == "kpl.dom001.local"){
      return protocol + "//kplapi.dom001.local/api/"; 
    } 
    else if (hostname.toLowerCase() == "kpl.spvinfo.com"){
      return protocol + "//kplapi.spvinfo.com/api/"; 
    } 
    else {
      return protocol + "//localhost:5217/api/";
    }
  }

function getCurrentDateHour() {
  let d = new Date;
  let annee = d.getFullYear();
  let mois = d.getMonth() + 1;
  mois = (mois < 10) ? "0"+mois : mois;
  let jour = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
  let heure = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
  let minute = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
  return jour+"."+mois+"."+annee+"-"+heure+"."+minute;
}

// ********** SPVI **********

function ListeEntreprise(){
  return axios.get(getUrlWs() +'Parametre/getListeEntreprise', { headers: authHeader() });
}

function GetListeCodeIncidance(){
  return axios.get(getUrlWs() + 'Parametre/getListeCodeIncidance', { headers: authHeader() });
}

function SumTotalEnregistrement(listLot){
  // On declare la variable
  var sumTotal = 0;

  // On additionne tous les enregistrements
  listLot.fiche.forEach(element => {
    if(element != null){
      sumTotal += element.nbrEnregistrement;
    }
  });

  return sumTotal;
}

function SumTotalSaisieVerif(liste){

  // On recupere le nom de l'utilisateur
  let username = this.currentUser.username;

  // On declare la variable
  var sumTotal = 0;

  // On boucle sur les lots
  liste.forEach(element => {

    // Operateur est saisie
    if(element.operateurSaisie == username && element.operateurVerif != username){
      element.fiche.forEach(fiche => {
        sumTotal += fiche.nbrEnregistrement;
      })
    }
    // Operateur est saisie et verif
    else if(element.operateurVerif == username && element.operateurSaisie != username){
      element.fiche.forEach(fiche => {
        sumTotal += fiche.nbrEnregistrementVerif;
      })
    }
    // Sinon il est les deux
    else {
      if(element.statut == "vérification" || element.statut == "terminé"){
        element.fiche.forEach(fiche => {
          sumTotal += fiche.nbrEnregistrementVerif;
        })
      }
      else{
        element.fiche.forEach(fiche => {
          sumTotal += fiche.nbrEnregistrement;
        })
      }
    }
    
  })

  return sumTotal;
}

function SumTotalSaisie(liste){

  // On declare la variable
  var sumTotal = 0;

  // On boucle sur les lots
  liste.forEach(element => {

    element.fiche.forEach(fiche => {
      sumTotal += fiche.nbrEnregistrement;
    })
     
  })

  return sumTotal;
}

function SumTotalVerif(liste){
    // On declare la variable
    var sumTotal = 0;

    // On boucle sur les lots
    liste.forEach(element => {
      element.fiche.forEach(fiche => {
        sumTotal += fiche.nbrEnregistrementVerif;
      })

    })

    return sumTotal;
}

function SumTotalVerifLot(lot){
  // On declare la variable
  var sumTotal = 0;

  // On boucle sur les fiches
  lot.fiche.forEach(fiche => {
    sumTotal += fiche.nbrEnregistrementVerif;
  })


  return sumTotal;
}

function SumTotalEnregistrementVerif(listLot){
    // On declare la variable
    var sumTotal = 0;

    // On additionne tous les enregistrements
    listLot.fiche.forEach(element => {
      if(element != null){
        sumTotal += element.nbrEnregistrementVerif;
      }
    });
  
    return sumTotal;
}

function Semaine(dateDebut){
  if(dateDebut==null){
    return ""
  }

  var dateDebutFR = GetDateFormatteeFr(dateDebut); 

  // On recupere le jour, le mois et l'année
  let oneDay = dateDebutFR.substring(0,2);
  let month = dateDebutFR.substring(3,5);
  let year = dateDebutFR.substring(6,10)

  var newDate = new Date(year + "-" + month + "-" + oneDay);

  // On recupere la semaine
  var janvier = new Date(newDate.getFullYear(),0,1);
  var numberOfDays = Math.floor((newDate - janvier) / (24 * 60 * 60 * 1000));
  return Math.ceil((newDate.getDay() + 1 + numberOfDays) / 7);
}

function GetDateMonthDay(d){
  if(d==null){
    return "-";
  }
  if(typeof d=="string"){
    d= new Date(d);
  }
  var jj = (d.getDate() < 10) ? "0" : "";
  jj += d.getDate();
  var mm = (d.getMonth() + 1 < 10) ? "0" : "";
  mm += d.getMonth() + 1;
  return jj + "/" + mm ;
}

function GetDateSevenDays(d){
  if(d==null){
    return "-";
  }
  if(typeof d=="string"){
    d= new Date(d) ;
  }

  d.setDate(d.getDate() + 6);

  var jj = (d.getDate() < 10) ? "0" : "";
  jj += d.getDate();
  var mm = (d.getMonth() + 1 < 10) ? "0" : "";
  mm += d.getMonth() + 1;
  var yyyy = d.getFullYear().toString();
  var yy = yyyy.substr(2);

  return jj + "/" + mm + "/" + yy;
}



// ********** End SPVI **********

function GetDateFormatteeFr(d) {
  if(d==null){
    return "-";
  }
  if(typeof d=="string"){
    d= new Date(d);
  }
  var jj = (d.getDate() < 10) ? "0" : "";
  jj += d.getDate();
  var mm = (d.getMonth() + 1 < 10) ? "0" : "";
  mm += d.getMonth() + 1;
  var yyyy = d.getFullYear();
  return jj + "/" + mm + "/" +yyyy;
}
function GetDateEtHeureFormatteeFr(d) {
  if(typeof d=="string" ){
    d= new Date(d);
  }
  var jj = (d.getDate() < 10) ? "0" : "";
  jj += d.getDate();
  var mm = (d.getMonth() + 1 < 10) ? "0" : "";
  mm += d.getMonth() + 1;
  var yyyy = d.getFullYear();
  var hh = d.getHours();
  var MM = (d.getMinutes() < 10) ? "0" + d.getMinutes() : "" + d.getMinutes();
  return jj + "/" + mm + "/" +yyyy+" à "+hh+"h"+MM;
}

function formatJoliNombre(nbr){
  if(nbr==null || nbr==undefined ){
    nbr=0;
  }
  if(typeof nbr=="string"){
    nbr= parseFloat(nbr.replace(",", ".").replace(/[^0-9|.|-]/g,""))
  }  
  if(isNaN(nbr)){
    nbr=0;
  }
  var formatter = Intl.NumberFormat().format(nbr.toFixed(2)).replace(',', '.');

  //pour avoir tjs 2 chiffre après la virgule
  if(!formatter.match(/\.[0-9]{2}/) && formatter.match(/\.[0-9]{1}/)  ){
    formatter +="0";
  }
  else if(formatter.indexOf(".")==-1){
    formatter+=".00";
  }

  return formatter;
}

export {
    getCurrentUser,getUrlWs, getCurrentDateHour,isSessionExpired, 
    GetDateFormatteeFr,GetDateEtHeureFormatteeFr,
    formatJoliNombre, ListeEntreprise, GetListeCodeIncidance,
    SumTotalEnregistrement, GetDateMonthDay, GetDateSevenDays,
    Semaine, SumTotalEnregistrementVerif, SumTotalVerif, SumTotalSaisie,
    SumTotalSaisieVerif, SumTotalVerifLot
}