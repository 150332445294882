<template>
    <div v-if="loading" id="loading">
        <div class="spinner-border" role="status"></div> Chargement...
    </div>
</template>
<script>
export default {
    name: "Spinner",
    currentUser: {},
    data() {
        return {
        };
    },
    props: {
        loading: Boolean,
    },


};
</script>
<style lang="scss" scoped>
#loading {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    text-align: center;
    font-size: 1.5em;
    color: #FFFFFF;
    .spinner-border {
        margin-top: 10%;
    }
}

</style>