<template>
  <Navigation pageName="Home" />
  <Spinner :loading="Loading" />
  <div class="container main-content">

    <div class="row">
      <div class="col-lg-12 title-page">
        Saisies des lots
      </div>
      <div class="col-lg-12 title-table color-vert">
        Liste des lots à saisir
      </div>

      <!-- Filtre -->
      <div class="col-lg-2">
        Recherche
        <input type="text" class="form-control" placeholder="N° Lot" v-model="searchValue">
      </div>

      <div class="col-lg-3">
        Semaine
        <br>
        <multi-select :options="listeDateSaisir" :selected-options="items" placeholder="Choix de la date"
          @select="GetChangeSemaineSaisir">
        </multi-select>
      </div>

      <div class="col-lg-2">
        Statut
        <br>
        <multi-select :options="listeStatutFilter" :selected-options="itemsStatutFilter" placeholder="Statut"
          @select="GetChangeStatutSaisir">
        </multi-select>
      </div>
      <!-- End Filtre -->

      <div class="col-lg-12 mt-2 mb-5">
        <EasyDataTable table-class-name="customize-table-green" header-text-direction="center"
          :headers="headersLotsSaisir" :items="listeLotsSaisir" :search-field="searchField" :search-value="searchValue"
          :hide-footer="true" alternating>

          <template #item-numero="item">
            <div class="text-center">
              <span>{{ item.idEntrepriseNavigation.code }}</span>
              <strong>
                  {{ GrasCompteur(item.numero, item.idEntrepriseNavigation.code) }}
              </strong>
            </div>
          </template>

          <template #item-idEntrepriseNavigation="item">
            <div class="text-center">
              <span class="text-uppercase">{{ item.idEntrepriseNavigation.code }} - {{ item.idEntrepriseNavigation.nom
              }}</span>
            </div>
          </template>

          <template #item-date="item">
            <div v-if="item.reliquat == true" class="border-classe background-blue border-blue text-white text-center">R
            </div>
            <div v-else class="text-center">
              S{{ Semaine(item.date) }} du
              {{ GetDateMonthDay(item.date) }}
              au {{ GetDateSevenDays(item.date) }}
            </div>
          </template>

          <template #item-statut="item">
            <div class="text-center">
              <span v-if="item.statut == 'a faire'">
                À faire
              </span>
              <span v-else class="firstLetterUpper">
                {{ item.statut }}
              </span>
            </div>
          </template>

          <template #item-nbrSaisie="item">
            <div class="text-center">
              {{ this.SumTotalEnregistrement(item) }}
            </div>
          </template>

          <template #item-nbrVerif="item">
            <div class="text-center">
              {{ this.SumTotalEnregistrementVerif(item) }}
              </div>
          </template>

          <template #item-option="item">

            <!-- Si la saisie est pour l'utilisateur -->
            <span
              v-if="this.currentUser && item.operateurSaisie == this.currentUser.username && item.operateurVerif != this.currentUser.username">
              <span v-if="item.statut == 'a faire' || item.statut == 'en cours'">
                <div v-if="!item.fiche.length">
                  <a type="button" class="btn btn-blue btn-sm col-lg-12" @click="ContinueSaisie(item.idLot)">Saisir</a>
                </div>
                <div v-else>
                  <a type="button" class="btn btn-yellow btn-sm col-lg-12" @click="ContinueSaisie(item.idLot)">Continuer
                    la saisie</a>
                </div>
              </span>
              <span v-else>
                Saisie terminée le {{ GetDateFormatteeFr(item.dateFinSaisie) }}
              </span>
            </span>

            <!-- Si la verification est pour l'utilisateur -->
            <span
              v-if="this.currentUser && item.operateurVerif == this.currentUser.username && item.operateurSaisie != this.currentUser.username">
              <span v-if="item.statut == 'vérification'">
                <a type="button" class="btn btn-red btn-sm col-lg-12" @click="VerifSaisie(item.idLot)">Vérifier</a>
              </span>
              <span v-else-if="item.statut == 'terminé'">
                Vérification terminée le {{ GetDateFormatteeFr(item.dateFinVerif) }}
              </span>
            </span>

            <!-- Si la saise est pour l'utilisateur et la verification -->
            <span
              v-if="this.currentUser && item.operateurVerif == this.currentUser.username && item.operateurSaisie == this.currentUser.username">
              <span v-if="item.statut == 'a faire' || item.statut == 'en cours'">
                <div v-if="!item.fiche.length">
                  <a type="button" class="btn btn-blue btn-sm col-lg-12" @click="ContinueSaisie(item.idLot)">Saisir</a>
                </div>
                <div v-else>
                  <a type="button" class="btn btn-yellow btn-sm col-lg-12" @click="ContinueSaisie(item.idLot)">Continuer
                    la saisie</a>
                </div>
              </span>
              <span v-else-if="item.statut == 'vérification'">
                <a type="button" class="btn btn-red btn-sm col-lg-12" @click="VerifSaisie(item.idLot)">Vérifier</a>
              </span>
              <span v-else-if="item.statut == 'terminé'">
                Vérification terminée le {{ GetDateFormatteeFr(item.dateFinVerif) }}
              </span>
            </span>

          </template>
        </EasyDataTable>

        <div class="col-lg-2 offset-lg-6 total-saisie">
          Total saisie
          <span class="chiffre-totaux">{{ SumTotalVerif(this.listeLotsSaisir) }}</span>
        </div>


      </div>


    </div>

    <div class="row">
      <div class="col-lg-12 title-table color-vert">
        Historique des lots saisis
      </div>

      <!-- Filtre -->
      <div class="col-lg-2">
        Recherche
        <input type="text" class="form-control" id="" placeholder="N° Lot" v-model="searchValueHistorique">
      </div>

      <div class="col-lg-3">
        Semaine
        <br>
        <multi-select :options="listeDateHistorique" :selected-options="itemsHistorique" placeholder="Choix de la date"
          @select="GetChangeSemaineHistorique">
        </multi-select>

      </div>
      <!-- End Filtre -->

      <div class="col-lg-12 mt-2">
        <EasyDataTable table-class-name="customize-table-grey" header-text-direction="center"
          :headers="headersHistoriqueLots" :items="historiquelots" :search-field="searchFieldHistorique"
          :search-value="searchValueHistorique" :hide-footer="true" alternating>

          <!-- Lot -->
          <template #item-numero="item">
            <div class="text-center">
              <span>{{ item.idEntrepriseNavigation.code }}</span>
              <strong>
                  {{ GrasCompteur(item.numero, item.idEntrepriseNavigation.code) }}
              </strong>
            </div>
          </template>

          <!-- Entreprise -->
          <template #item-idEntrepriseNavigation="item">
            <div class="text-center text-uppercase">
              {{ item.idEntrepriseNavigation.code }} - {{ item.idEntrepriseNavigation.nom }}
            </div>
          </template>

          <!-- Semaine -->
          <template #item-date="item">
            <div class="text-center">
              <div v-if="item.reliquat == true" class="border-classe background-blue border-blue text-white text-center">R
              </div>
              <div v-else class="text-center">
                S{{ Semaine(item.date) }} du
                {{ GetDateMonthDay(item.date) }}
                au {{ GetDateSevenDays(item.date) }}
              </div>
            </div>
          </template>

          <!-- Statut -->
          <template #item-statut="item">
            <div class="text-center">
              <span v-if="item.statut == 'terminé'">
                <span class="text-capitalize">{{ item.statut }}</span>
              </span>
              <span v-else>
                <span class="text-capitalize">{{ item.statut }}</span> le {{ GetDateFormatteeFr(item.dateTransmission) }}
              </span>
            </div>
          </template>

          <template #item-nbrSaisie="item">
            <div class="text-center">
              {{ this.SumTotalEnregistrement(item) }}
            </div>
          </template>

          <!-- Nbr enreg. -->
          <template #item-nbrVerif="item">
            <div class="text-center">
              {{ this.SumTotalEnregistrementVerif(item) }}
            </div>
          </template>

          <template #item-dateFinSaisie="item">
            <div class="text-center">
              Verif. terminée le {{ GetDateFormatteeFr(item.dateFinVerif) }}
            </div>
          </template>

        </EasyDataTable>
        <div class="col-lg-2 offset-lg-6 total-saisie">
          Total saisie
          <span class="chiffre-totaux">{{ SumTotalVerif(historiquelots) }}</span>
        </div>
      </div>

    </div>

  </div>
</template>
  
<script>
import { ref } from "vue";
import Spinner from "@/components/Spinner.vue";
import { MultiSelect } from "vue-search-select";
import Navigation from "@/components/Nav.vue";
import {
  getUrlWs, isSessionExpired, SumTotalEnregistrement,
  GetDateFormatteeFr, GetDateMonthDay, GetDateSevenDays,
  Semaine, SumTotalEnregistrementVerif, SumTotalSaisie, SumTotalVerif,
  SumTotalSaisieVerif
} from "@/utilities";
import authHeader from '@/services/auth-header';
import _ from 'lodash';

export default {
  name: "Home",
  data() {
    return {
      Loading: true,
      sumEnregistrement: "",

      listeLotsSaisir: [],
      historiquelots: [],

      // Filter lots à saisir
      listeDateSaisir: [],

      searchText: '', // If value is falsy, reset searchText & searchItem
      items: [],
      lastSelectItem: {},


      listeStatutFilter: [],

      searchTextStatutFilter: '', // If value is falsy, reset searchText & searchItem
      itemsStatutFilter: [],
      lastSelectItemStatutFilter: {},
      // End filter lots à saisir


      // Filter lots historique
      listeDateHistorique: [],

      searchTextHistorique: '', // If value is falsy, reset searchText & searchItem
      itemsHistorique: [],
      lastSelectItemHistorique: {},

      // Easy table
      searchField: ["numero"],
      searchValue: ref(""),

      headersLotsSaisir: [
        {
          value: 'numero',
          text: 'Lot',
          sortable: true
        },
        {
          value: 'idEntrepriseNavigation',
          text: 'Entreprise',
          sortable: true
        },
        {
          value: 'date',
          text: 'Semaine',
          sortable: true
        },
        {
          value: 'statut',
          text: 'Statut',
          sortable: true
        },
        {
          value: 'nbrSaisie',
          text: 'Nbr enreg. saisie',
          sortable: true,
        },
        {
          value: 'nbrVerif',
          text: 'Nbr enreg. vérif.',
          sortable: true,
        },
        {
          value: 'option',
          text: 'Options',
          sortable: true,
          width: 325
        }
      ],

      searchFieldHistorique: ["numero"],
      searchValueHistorique: ref(""),
      headersHistoriqueLots: [
        {
          value: 'numero',
          text: 'Lot',
          sortable: true
        },
        {
          value: 'idEntrepriseNavigation',
          text: 'Entreprise',
          sortable: true
        },
        {
          value: 'date',
          text: 'Semaine',
          sortable: true
        },
        {
          value: 'statut',
          text: 'Statut',
          sortable: true
        },
        {
          value: 'nbrSaisie',
          text: 'Nbr enreg. saisie',
          sortable: true,
        },
        {
          value: 'nbrVerif',
          text: 'Nbr enreg. vérif.',
          sortable: true,
        },
        {
          value: 'dateFinSaisie',
          text: 'Fin de saisie',
          sortable: true,
          width: 325
        }
      ]
    };
  },
  components: {
    Navigation,
    MultiSelect,
    Spinner
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    SumTotalEnregistrement,
    GetDateFormatteeFr,
    GetDateMonthDay,
    GetDateSevenDays,
    Semaine,
    SumTotalEnregistrementVerif,
    SumTotalSaisie,
    SumTotalVerif,
    SumTotalSaisieVerif,
    GetLotsSaisir() {
      this.Loading = true;
      this.$http
        .get(getUrlWs() + 'Home/getListeLotsSaisir', { headers: authHeader() })
        .then(response => {

          this.listeLotsSaisir = response.data;

          // On recupere toute les dates 
          var date = [];

          this.listeLotsSaisir.forEach(element => {

            if (element.reliquat != true) {
              date.push(element.date)
            }

          });

          // On enleve les doublons
          var dateUnique = [...new Set(date)];

          dateUnique.forEach(element => {

            var allDate = {};
            allDate.value = element;
            allDate.text = new Date(element);
            allDate.text = "S" + this.Semaine(element) + " du " + this.GetDateMonthDay(element) + " au " + this.GetDateSevenDays(element);

            this.listeDateSaisir.push(allDate);

          })

          // On trie notre liste
          this.listeDateSaisir = _.sortBy(this.listeDateSaisir, 'value');



          // On recupere les valeurs au format souhaiter par vue-search-select
          response.data.forEach(lot => {
            var allStatutLot = {};

            allStatutLot.value = lot.statut;
            if (lot.statut == "a faire") {
              allStatutLot.text = "À" + lot.statut.slice(1);
            }
            else if (lot.statut == "transmis") {
              return;
            }
            else {
              allStatutLot.text = lot.statut.charAt(0).toUpperCase() + lot.statut.slice(1);
            }

            this.listeStatutFilter.push(allStatutLot);

          })

          // On enleve les doublons
          const unique = [];
          for (const item of this.listeStatutFilter) {
            const isDuplicate = unique.find((obj) => obj.value == item.value);
            if (!isDuplicate) {
              unique.push(item);
            }
          }

          this.listeStatutFilter = unique;

          // On trie notre liste
          this.listeStatutFilter = _.sortBy(this.listeStatutFilter, 'value');

          this.Loading = false;

        }).catch((err) => {
          if (!isSessionExpired()) {
            var msgErr = 'Impossible de charger la liste des lots à saisir.';

            if (err.response && err.response.data) {
              msgErr = err.response.data;
            }

            this.$toast.error(msgErr);
            this.Loading = false;
          }
        });
    },
    GetHistoriqueLots() {
      this.$http
        .get(getUrlWs() + 'Home/getHistoriqueLots', { headers: authHeader() })
        .then(response => {

          this.historiquelots = response.data;

          // On ajout les valeurs au format souhaiter par vue-search-select
          response.data.forEach(lot => {
            var allDateLot = {};

            if (lot.reliquat != true) {

              allDateLot.value = lot.date;
              allDateLot.text = "S" + this.Semaine(lot.date) + " du " + this.GetDateMonthDay(lot.date) + " au " + this.GetDateSevenDays(lot.date);

              this.listeDateHistorique.push(allDateLot);
            }
            else {

              let allDateReliquat = [];

              // On recherche la date la plus petites du lot
              lot.fiche.forEach(fiche => {
                allDateReliquat.push(fiche.dateReliquat);
              })

              // On trie notre tableau
              allDateReliquat = allDateReliquat.sort();

              // On set nos valeurs 
              allDateLot.value = allDateReliquat[0];
              allDateLot.text = "S" + this.Semaine(allDateReliquat[0]) + " du " + this.GetDateMonthDay(allDateReliquat[0]) + " au " + this.GetDateSevenDays(allDateReliquat[0]);

              this.listeDateHistorique.push(allDateLot);
            }
          });


          // On enleve les doublons
          const unique = [];
          for (const item of this.listeDateHistorique) {
            const isDuplicate = unique.find((obj) => obj.value == item.value);
            if (!isDuplicate) {
              unique.push(item);
            }
          }

          this.listeDateHistorique = unique;

          // On trie notre liste
          this.listeDateHistorique = _.sortBy(this.listeDateHistorique, 'value');


        }).catch((err) => {
          if (!isSessionExpired()) {
            var msgErr = 'Impossible de charger l\'historique des lots saisis.';

            if (err.response && err.response.data) {
              msgErr = err.response.data;
            }

            this.$toast.error(msgErr);
          }
        });
    },
    GrasCompteur(numLot, codeEnt){
      let resultat = numLot.toString().replace(codeEnt.toString(), "");

      return resultat;
    },




    // ************************
    // ********* Filter *******
    // ************************

    // Liste lots à saisir
    GetChangeSemaineSaisir(items, lastSelectItem) {
      this.items = items;
      this.lastSelectItem = lastSelectItem;

      if (this.items[0] != null || this.itemsStatutFilter != null) {

        let allDate = []

        this.items.forEach(element => {
          allDate.push(element.value);
        });

        // On met les valeurs dans le parametre
        let param = {
          dates: allDate,
          statut: ''
        }

        if (this.itemsStatutFilter != null) {

          let allStatut = [];

          this.itemsStatutFilter.forEach(element => {
            allStatut.push(element.value);
          });

          param['statut'] = allStatut;
        }

        this.$http
          .post(getUrlWs() + 'Home/GetFilterSaisir', param, { headers: authHeader() })
          .then(response => {

            this.listeLotsSaisir = response.data;

          }).catch((err) => {
            if (!isSessionExpired()) {
              var msgErr = 'Erreur interne.';

              if (err.response && err.response.data) {
                msgErr = err.response.data;
              }

              this.$toast.error(msgErr);
            }
          });

      } else {
        this.GetLotsSaisir();
      }
    },
    // deselect option
    reset() {
      this.items = [] // reset
    },
    // select option from parent component
    selectFromParentComponent() {
      this.items = _.unionWith(this.items, [this.options[0]], _.isEqual)
    },



    GetChangeStatutSaisir(itemsStatutFilter, lastSelectItemStatutFilter) {

      this.itemsStatutFilter = itemsStatutFilter;
      this.lastSelectItemStatutFilter = lastSelectItemStatutFilter;

      if (this.items[0] != null || this.itemsStatutFilter != null) {

        let allDate = []

        this.items.forEach(element => {
          allDate.push(element.value);
        });

        // On met les valeurs dans le parametre
        let param = {
          dates: allDate,
          statut: ''
        }

        if (this.itemsStatutFilter != null) {

          let allStatut = [];

          this.itemsStatutFilter.forEach(element => {
            allStatut.push(element.value);
          });

          param['statut'] = allStatut;
        }

        this.$http
          .post(getUrlWs() + 'Home/GetFilterSaisir', param, { headers: authHeader() })
          .then(response => {

            var array = [];

            // On bouclue sur les liste
            response.data.forEach(function (lot) {
              // Si il est différents de transmis 
              if (lot.statut != "transmis") {
                array.push(lot);
              }
            })

            this.listeLotsSaisir = array;

          }).catch((err) => {
            if (!isSessionExpired()) {
              var msgErr = 'Erreur interne.';

              if (err.response && err.response.data) {
                msgErr = err.response.data;
              }

              this.$toast.error(msgErr);
            }
          });

      } else {
        this.GetLotsSaisir();
      }
    },
    // deselect option
    resetStatutSaisir() {
      this.itemsStatutFilter = [] // reset
    },
    // select option from parent component
    selectFromParentComponentStatutSaisir() {
      this.itemsStatutFilter = _.unionWith(this.itemsStatutFilter, [this.options[0]], _.isEqual)
    },




    // Liste historique lots à saisir
    GetChangeSemaineHistorique(itemsHistorique, lastSelectItemHistorique) {
      this.itemsHistorique = itemsHistorique;
      this.lastSelectItemHistorique = lastSelectItemHistorique;

      if (this.itemsHistorique[0] != null) {

        let allDate = []

        this.itemsHistorique.forEach(element => {
          allDate.push(element.value);
        });

        // On met les valeurs dans le parametre
        let param = {
          dates: allDate
        }

        this.$http
          .post(getUrlWs() + 'Home/GetChangeSemaineHistorique', param, { headers: authHeader() })
          .then(response => {

            // On recupere la reponse
            this.historiquelots = response.data;

          }).catch((err) => {
            if (!isSessionExpired()) {
              var msgErr = 'Erreur interne.';

              if (err.response && err.response.data) {
                msgErr = err.response.data;
              }

              this.$toast.error(msgErr);
            }
          });

      } else {
        this.GetHistoriqueLots();
      }
    },
    // deselect option
    resetHistorique() {
      this.itemsHistorique = [] // reset
    },
    // select option from parent component
    selectFromParentComponentHistorique() {
      this.itemsHistorique = _.unionWith(this.itemsHistorique, [this.options[0]], _.isEqual)
    },



    // Change statut
    // GetChangeStatut(paramStatut){
    //   this.$http
    //     .get(getUrlWs() + 'Home/getChangeStatut/' + paramStatut.target.value, { headers: authHeader() })
    //     .then(response => {

    //       this.listeLotsSaisir = response.data;

    //     }).catch((err) => {
    //       if (!isSessionExpired()) {
    //         var msgErr = 'Impossible de charger la liste des lots à saisir.';

    //         if (err.response && err.response.data) {
    //           msgErr = err.response.data;
    //         }

    //         this.$toast.error(msgErr);
    //       }
    //     });
    // },


    // ************************
    // ***** End Filter *******
    // ************************




    ContinueSaisie(id) {

      this.$router.push({ name: 'saisies', params: { idLot: id }, query: { verif: false } });

    },
    VerifSaisie(id) {

      this.$router.push({ name: 'saisies', params: { idLot: id }, query: { verif: true } });

    },

  },
  mounted() {
    this.GetLotsSaisir(),
      this.GetHistoriqueLots()

  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";
@import "@/scss/popper";
</style>